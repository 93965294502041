<template>
    <PendingHistory :columns="fetchColumns" :rows="rows" :loadingStates="loadingStates" @onPageChange="onPageChange($event)" :paginationOptions="paginationOptions" :totalRows="pageData?.total_count" :filterOptions="filterOptions" :filterOptionsAsync="filterOptionsAsync.value" @onColumnFilters="onColumnFilters($event)" @getSearchOptionsTick="getSearchOptionsTick($event)" @clearFilters="clearFilters" :columnFilters="columnFilters" />
</template>

<script>
import PendingHistory from "@shared/components/pending-activity";
import axios from "@/axios";
import { uniqBy } from "lodash";
import { uuid } from "vue-uuid";
import { DateTime } from "luxon";

const columns = [
    {
        label: "Case ID",
        field: "visible_case_id",
        config: {
            filter: true,
            type: "link",
        },
    },
    {
        label: "Subject First Name",
        field: "candidate_first_name.label",
        query_key: "candidate_first_name",
        config: {
            filter: true,
        },
    },
    {
        label: "Subject Last Name",
        field: "candidate_last_name.label",
        query_key: "candidate_last_name",
        config: {
            filter: true,
        },
    },
    {
        label: "Activity Type",
        field: "activity_type.label",
        query_key: "activity_type",
        config: {
            filter: true,
        },
    },
    {
        label: "Status",
        field: "status.label",
        query_key: "status",
        config: {
            filter: true,
        },
    },
    {
        label: "Due Date",
        field: "due_date.label",
        query_key: "due_date.label",
        config: {
            filter: false,
        },
    },
    {
        label: "Assignee Role",
        field: "assignee_role.label",
        query_key: "assignee_role",
        config: {
            filter: true,
        },
    },
    {
        label: "Assignee Name",
        field: "assignee",
        query_key: "assignee",
        config: {
            filter: true,
        },
    },
    {
        label: "Check",
        field: "check",
        query_key: "check",
        config: {
            filter: true,
        },
    },
    {
        label: "Title",
        field: "title.label",
        query_key: "title",
        config: {
            filter: false,
            type: "rich_text",
        },
        tdClass: "truncate max-w-sm",
    },
];

export default {
    name: "activity-history-notification",
    data() {
        return {
            rows: [],
            loadingStates: {
                activity: false,
            },
            paginationOptions: {
                enabled: true,
                mode: "remote",
            },
            pageData: null,
            searchTimeout: null,
            currentPageData: {
                page: 1,
                count: 10,
            },
            searchLoading: {
                value: {},
            },
            filterOptionsAsync: {
                value: null,
            },
            columnFilters: null,
        };
    },
    components: {
        PendingHistory,
    },
    computed: {
        filterOptions() {
            return this.getFilterOptions(this.fetchColumns, this.rows);
        },
        fetchColumns() {
            return columns.map((el) => ({
                ...el,
                config: {
                    ...el.config,
                    isLoading: !!this.searchLoading.value[el.field],
                },
            }));
        },
    },
    async mounted() {
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        this.loadingStates.activity = true;
        await this.fetchPendingHistory();
    },
    methods: {
        async clearFilters() {
            this.columnFilters = null;
            await this.fetchPendingHistory(this.currentPageData.page, this.currentPageData.count);
        },
        async fetchPendingHistory(page, count, new_payload) {
            this.loadingStates.activity = true;
            try {
                let payload = {
                    activity_type: new_payload?.activity_type?.value || null,
                    assignee_role: new_payload?.assignee_role?.value || null,
                    assignee: new_payload?.assignee?.value || null,
                    acknowledged: new_payload?.acknowledged?.value || null,
                    candidate_first_name: new_payload?.candidate_first_name?.value || null,
                    candidate_last_name: new_payload?.candidate_last_name?.value || null,
                    visible_case_id: new_payload?.visible_case_id?.value || null,
                    check: new_payload?.check?.value || null,
                    page: page || 1,
                    count: count || 10,
                    
                };
                const { data } = await axios.post(`dashboard-notifications/tenant`, payload);
                this.rows = data?.data?.data;
                const formateDate = (date) => (date ? DateTime.fromISO(date).toFormat("yyyy-LL-dd") : "");
                this.rows = this.rows.map((ele) => ({ ...ele, due_date: { value: formateDate(ele.due_date.value), label: formateDate(ele.due_date.label) } }));
                this.pageData = data?.data?.page;
            } catch (error) {
                console.log(">>>> error", error);
            }
            this.loadingStates.activity = false;
        },
        async onPageChange(data) {
            let { currentPerPage, currentPage } = data;
            this.currentPageData = {
                page: currentPage,
                count: currentPerPage,
            };
            await this.fetchPendingHistory(currentPage, currentPerPage);
        },
        async onColumnFilters(data) {
            let payload = data.columnFilters;
            this.columnFilters = data?.columnFilters ? { ...data?.columnFilters } : null;
            await this.fetchPendingHistory(this.currentPageData.page, this.currentPageData.count, payload);
        },
        getSearchOptionsTick(payload) {
            console.log("payload :>> ", payload);
            if (this.searchTimeout) clearTimeout(this.searchTimeout);
            const delay = 500;
            this.searchTimeout = setTimeout(() => {
                this.getSearchOptions(payload);
            }, delay);
        },

        async getSearchOptions(payload) {
            // if searching is canceled reset async filter options for the field:
            if (!payload.value) this.$set(this.filterOptionsAsync, "value", null);
            else {
                // set 'loading' prop for the column we are searching in:
                this.$set(this.searchLoading, "value", true);

                try {
                    let filters = {};
                    if (this.columnFilters) {
                        Object.entries(this.columnFilters).forEach(([key, value]) => {
                            let obj = {};
                            obj[key] = value.value;
                            filters = { ...filters, ...obj };
                        });
                    }
                    const requestData = {
                        ...filters,
                        [payload.column.query_key]: payload.value,
                    };
                    console.log("requestData :>> ", requestData);
                    const { data } = await axios.post(`dashboard-notifications/tenant`, requestData);
                    // set async options:
                    this.$set(this.filterOptionsAsync, "value", this.getFilterOptions(this.fetchColumns, data.data.data));
                } catch (error) {
                    console.log("error :>> ", error);
                }

                this.$set(this.searchLoading, "value", false);
            }
        },
        getFilterOptions(columns, rows) {
            return columns.reduce((result, curr) => {
                const fields = curr.field.split(".");
                result[curr.field] = rows
                    .filter((row) => {
                        return fields?.length === 1 ? row[curr.field] : row[fields[0]][fields[1]];
                    })
                    .map((row) => {
                        let obj = { id: uuid.v4(), name: fields?.length === 1 ? row[curr.field] : row[fields[0]][fields[1]], value: row[fields[0]]?.value };
                        if (!obj.value) {
                            obj.value = obj.name;
                        }
                        return obj;
                    });
                result[curr.field] = uniqBy(result[curr.field], "name");
                return result;
            }, {});
        },
    },
};
</script>

<style></style>
